import React from "react"

import colors from "../colors"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import PageWrapper from "../components/pageWrapper"

const iconProps = {
  size: "60px",
  color: colors.darkPurple,
}

const ThankYou = () => {
  return (
    <PageLayout>
      <SEO
        title="Thank You - The Long Term Care Insurance Group and Corey Rieck"
        ogDescription="Thank you for contacting us. We will be calling you soon."
      />
      <PageWrapper>
        <h1>Thank you!</h1>
        <p>
          Corey Rieck and his team will be contacting you with more information about your long term care insurance options.
        </p>
      </PageWrapper>
    </PageLayout>
  )
}

export default ThankYou
